<template>
	<div class="box">
		<LoadingSpinner v-if="processing" />
		<template v-else>
	        <div class="row my-3 text-center" v-for="video in videos" v-bind:key="video.media_amazon_url">
	        	<div class="col">
		        	<video controls height="350" :src="video.media_amazon_url"></video>
		            <b-form-checkbox v-model="video_selected" :value="video"></b-form-checkbox>
		        </div>
	        </div>

	        <div class="text-center mt-3">
	            <b-button variant="primary" :disabled="loading_ajout" @click="add_video">
	                <font-awesome-icon v-if="loading_ajout" :icon="['fas', 'spinner']" pulse />
	                {{ $t('global.ajouter') }}
	            </b-button>
	        </div>
	    </template>
	</div>
</template>

<script type="text/javascript">
    import HorseMixin from "@/mixins/Horse.js";
    import Notes from "@/mixins/Notes.js";
    import File from "@/mixins/File.js";

	export default {
		name: 'ImportVideo',
		mixins: [HorseMixin, Notes, File],
		props: ['horse_id'],
		data () {
			return {
				videos: [],
				processing: false,
				video_selected: null,
				loading_ajout: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.processing = true
				this.videos = await this.getVideoConcours(this.horse_id)
				this.processing = false
			},

			async add_video() {
				this.loading_ajout = true
				const note = await this.saveNote(this.horse_id, 0, 0, 'Concours', new Date().toFormattedString(), '', this.video_selected.media_disabled, [])
				await this.uploadEmbeddMedia(this.video_selected.media_amazon_url, note.note_id, "App\\Model\\Note")
				this.loading_ajout = true
				this.$router.push({ name: 'HorseFicheNotes', params: this.horse_id })
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>